
import React from 'react';
import Wall from '../images/wallpaper.jpg';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { Button } from './Button';
import './Ref.css';




function Ref() {
    return (
        <section id="first" className='pricing__section' >
        <div className='pricing__wrapper'>
            <div className='pricing__container'>
                <div className='pricing__container-card'>
                    <div className='pricing__container-cardInfo'>
                        <div className='icon'>
                            <i class="fas fa-building"></i>
                        </div>
                        <h3>Immeuble à usage bureautique </h3>
                        <ul> <li> - El baraka</li>
                            <li> - LNCM</li>
                            <li> - Transcom</li>
                            <li> - Dar El Marsa</li>

                        </ul>
                        <NavHashLink to="/immeuble#Immeuble-à-usage-bureautique"><Button buttonSize='btn--wide' buttonColor='primary'>
                            Plus
                        </Button>
                        </NavHashLink>
                    </div>
                </div>
                <div className='pricing__container-card'>
                    <div className='pricing__container-cardInfo'>
                        <div className='icon'>
                            <i class="fas fa-desktop"></i>
                        </div>
                        <h3>Data center </h3>
                        <ul>
                            <li> - UIB</li>
                            <li> - BH </li>
                            <li> - ONAS</li>
                            <li> - LNCM ...</li>
                        </ul>

                        <NavHashLink to="/data#data"><Button buttonSize='btn--wide' buttonColor='blue'>
                            Plus
                        </Button>
                        </NavHashLink>
                    </div>
                </div>
                <div className='pricing__container-card'>
                    <div className='pricing__container-cardInfo'>
                        <div className='icon'>
                            <i class="fas fa-redo"></i>
                        </div>
                        <h3>Poste de transformation et Groupes électrogéne</h3>
                        <ul>
                            <li> - El baraka</li>
                            <li> - Misfat</li>
                            <li> - Sartorius stedim...</li>
                           
                        </ul>
                        <NavHashLink to="/ge#groupe"><Button buttonSize='btn--wide' buttonColor='primary'>
                            Plus
                        </Button></NavHashLink>

                    </div>
                </div>
                <div className='pricing__container-card'>
                    <div className='pricing__container-cardInfo'>
                        <div className='icon'>
                            <i class="fas fa-industry"></i>
                        </div>
                        <h3>Industrie</h3>
                        <ul>
                            <li> - Mall of sousse</li>
                            <li> - Volvo</li>
                            <li> - Misfat </li>
                            <li> -  Utique Gaz ...</li>
                        </ul>
                        <NavHashLink to="/industrie#industrie"><Button buttonSize='btn--wide' buttonColor='primary'>
                            Plus
                        </Button></NavHashLink>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Ref
