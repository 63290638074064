import React from 'react';
import './Fort.css';
import four from '../images/4.png';
import five from '../images/5.jpg';
import six from '../images/6.png';
import seven from '../images/7.jpg';
import Sidebarr from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';



function Fort() {
    return (
        <>
        <Navbar/>
        <div className="for">
            <Sidebarr />
            <div className="contenu">

                <div className="texts">
                    <p>The function of strong current is to carry energy through the power grid. Its use can power light sources, sockets, air conditioning and heating systems requiring high electrical intensity. <br />

                        A strong current has an intensity of a few hundred mA to several kA. </p>
                </div>
                <div className="partone">
                    <div className="imag">
                        <img src={four} alt="" />

                    </div>
                    <div className="tex">
                        <p>Strong current flows into the home through the electricity meter. It is then routed to the electrical panel. In the electrical panel, each circuit is defined and protected by a circuit breaker. These circuits can represent rooms in the house, outlets, lighting or appliances. Each home must have at least 4 different electrical circuits. The strong current is then routed through cables to outlets and switches in the apartment or house.</p>
                    </div>

                </div>
                <div className="imagess">
                    <img src={five} alt="" />

                    <img src={seven} alt="" />
                    <img src={six} alt="" />



                </div>

            </div>
        </div>
        <Footer />

        </>
    )
}

export default Fort
