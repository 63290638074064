import React,{useState} from 'react'
import './Login.css'

import { useHistory } from 'react-router'

import axios from 'axios'
function Login(props) {
    const [email,setemail] = useState("")
    const [MotDePasse,setMotDePasse] = useState("")
    const [result,setResult] = useState("")

    const history = useHistory()

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log({MotDePasse,email})
        
        const API_PATH = "/login.php"
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: {
                email,
                password:MotDePasse
            }
          })
            .then(result => {
              console.log(result)
              setResult(result.data)
              if(result.data =="connected successfully")
                props.setLoggedIn(true)
                history.push("/code")
            })
            .catch(error => {console.log("oups")});
    }

    return (
        <div className="log">
            
            <form className="formulaire" onSubmit={handleSubmit}>
                <div>
                <label><b>E-mail</b></label>

                <input value={email} onChange={(e)=>setemail(e.target.value)} type="email" placeholder="Entrer votre adresse mail " name="mail" required />
                </div>
                <div>
                <label><b>Mot de passe</b></label>
                <input value={MotDePasse} onChange={(e)=>setMotDePasse(e.target.value)} type="password" placeholder="Entrer le mot de passe"  name="pass" required />
                <p>{result}</p>
                </div>
                <input type="submit" id='submit' value='LOGIN' ></input>


            </form>
           

        </div>
    )
}

export default Login
