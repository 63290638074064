import React from 'react'
import './Faible.css';
import one from '../images/1.jpg'
import two from '../images/2.jpg'
import three from '../images/3.jpg'
import Sidebarr from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';





function Faible() {
    return (
        <>
                        <Navbar/>

        <div className="faib">
            <Sidebarr />
            <div className="contenu">

                <div className="texts">
                    <p> We call "low current" a wired network which transmits information but also allows communication: wired network, ground antenna, satellite antenna, audiovisual network, multimedia device (TV, home cinema ...), telephone, home automation, walkie - talkie, video surveillance, alarm, Ethernet (wired Internet broadcasting, not WiFi). </p>

                </div>
                <div className="imagess">
                    <img src={one} alt="" />
                    <img src={two} alt="" />

                    <img src={three} alt="" />


                </div>
                <div className="textdeux">
                    <p>In low current installations, it is possible to find: </p>
                    <ul>
                        <li>Flame detectors </li>
                        <li>Anti-theft alarms
                        </li>
                        <li>Smoke detectors
                        </li>
                        <li>Motion detectors
                        </li>
                        <li>CCTV cameras. CCTV.
                        </li>
                        <li>Computers</li>
                        <li>Phones</li>
                        <li>Optical fiber
                        </li>
                        <li>Loudspeaker communication system
                        </li>

                    </ul>

                </div>

            </div>
        </div>
        <Footer />


</> 
    )
}

export default Faible
