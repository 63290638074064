import React ,{useState} from 'react'
import '../../App.css';
import Footer from './Footer';

import Navbar from './Navbar';
import Trans from '../images/TRANSCOM.png'
import lncm from '../images/lncm.png'
import onh from '../images/onh.png'
import biat from '../images/biat.png'
import baraka from '../images/baraka.png'
import hotel from '../images/hotel.png'
import holding from '../images/holding.png'
import sarto from '../images/sarto.png'

import marsa from '../../images/marsa.png';

import axios from 'axios';
import './Office.css'

function Office() {
    const [codes,setCodes]=useState({
        Mall:false,
        Transcom:false,
        LNCM:false,
        ONH:false,
        BIAT:false,
        Stedim:false,
        UG:false,
        baraka:false,
        Hotel:false,
        Volvo:false,
        Business:false,
        Misfat:false,
        TSB:false,
        Onas:false,
        UIB:false,
        BT:false,
        BH:false,
        Meninix:false,
        Enda:false,
        Safia:false,
        Protein:false,
        Ennasim : false,
        baraka01 : false,
        Misfat01 : false, 
        LNCM01 : false , 
        Stedim01 : false , 
        Transcom01 : false ,
        UG01 : false , 
        baraka02 : false,
        Marsa:false,

  })
  const [result, setResult] = useState("")


    const testerCode = (id,name) => {
        console.log(document.getElementById(name).value)
            const API_PATH = "/testerCode.php"
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: {
                idSociete:id,
                password:document.getElementById(name).value
            }
          })
            .then(result => {
              console.log(result)
              setResult(result.data)
              if(result.data == "update done"){
                  setCodes({...codes,[name]:true})
                  console.log(codes)
                  console.log("changing")
              }
            })
            .catch(error => {console.log("oups")});
    }

    return (
     <>
     <Navbar/>
      <section className="grid" id="Office-building">
        <h1>Office building</h1>
        <div className="grid-inner">
            
            <div class="item">
                <div class="item-inner">
                    <img src={Trans} alt="" />
                </div>
                <div className="details">
                    <div className="content">

                        <p>Cconstruction of an office building located in the northern urban center TUNIS TRANSCOM {codes.Transcom?<><br/> 494.519,080dt</>:<></>}</p>
                            {codes.Transcom?<></>:<><input type="checkbox" id="shown" />
                        <label for="shown" class="show-btn">View Price</label>
                        <div className="prix">
                            <label for="shown" class="close-btn fas fa-times" title="close"></label>
                            <div class="msg">
                                Put the code here
                                <div className="datee">
                                    <input id="Transcom" type="password" required></input>
                                </div>
                                <div class="btn">
                                    <div class="inner"></div>
                                    <button onClick={()=>testerCode(2,"Transcom")}  type="submit">check</button>
                                </div>
                                <div className="p">
                                                <p  >{result}</p>
                                            </div>
                            </div>

                        </div></>}
                    </div>
                </div>
            </div>
            <div class="item">
                        <div class="item-inner">
                            <img src={marsa} alt="" />
                        </div>
                        <div className="details">
                            <div className="content">

                                <p>CONSTRUCTION PROJECT OF A 400 KVA TRANSFORMATION STATION {codes.Marsa ? <><br />86.000,000dt </> : <></>}</p>
                                {codes.Marsa ? <></> : <><input type="checkbox" id="shn" />
                                    <label for="shn" class="show-btn">Voir Prix</label>
                                    <div className="prix">
                                        <label for="shn" class="close-btn fas fa-times" title="close"></label>
                                        <div class="msg">
                                            Mettre le code ici
                                            <div className="datee">
                                                <input id="Marsa" type="password" required></input>
                                            </div>
                                            <div class="btn">
                                                <div class="inner"></div>
                                                <button onClick={() => testerCode(23, "Marsa")} type="submit">Verifier</button>
                                            </div>
                                            <div className="p">
                                                <p  >{result}</p>
                                            </div>
                                        </div>

                                    </div></>}
                            </div>
                        </div>
                    </div>
            <div class="item">
                <div class="item-inner">
                    <img src={lncm} alt="" />
                </div>
                <div className="details">
                    <div className="content">

                        <p>Installation et mise en marche d'un système de
                            détection incendie et un onduleur LNCM {codes.LNCM?<><br/> 78.880,000dt</>:<></>}</p>
                            {codes.LNCM?<></>:<><input type="checkbox" id="toshow" />
                        <label for="toshow" class="show-btn">View Price</label>
                        <div className="prix">
                            <label for="toshow" class="close-btn fas fa-times" title="close"></label>
                            <div class="msg">
                                Met le code ici                    <div className="datee">
                                    <input id="LNCM" type="password" required></input>
                                </div>
                                <div class="btn">
                                    <div class="inner"></div>
                                    <button onClick={()=>testerCode(3,"LNCM")} type="submit">check</button>
                                </div>
                                <div className="p">
                                                <p  >{result}</p>
                                            </div>
                            </div>

                        </div></>}
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item-inner">
                    <img src={onh} alt="" />
                </div>
                <div className="details">
                    <div className="content">

                        <p>ehabilitation of the electrical installation of
                  National Oil Office ONH{codes.ONH?<><br/> 175.000,000dt</>:<></>}</p>
                            {codes.ONH?<></>:<><input type="checkbox" id="tooshow" />
                        <label for="tooshow" class="show-btn">View Price</label>
                        <div className="prix">
                            <label for="tooshow" class="close-btn fas fa-times" title="close"></label>
                            <div class="msg">
                                Put the code here
                                <div className="datee">
                                    <input id="ONH" type="password" required></input>
                                </div>
                                <div class="btn">
                                    <div class="inner"></div>
                                    <button onClick={()=>testerCode(4,"ONH")} type="submit">check</button>
                                </div>
                                <div className="p">
                                                <p  >{result}</p>
                                            </div>
                            </div>

                        </div></>}
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item-inner">
                    <img src={biat} alt="" />
                </div>
                <div className="details">
                    <div className="content">
                        <p>BIAT ALAIN SAVARY incubator center{codes.BIAT?<><br/> 84.000,000dt</>:<></>}</p>
                            {codes.BIAT?<></>:<><input type="checkbox" id="showw" />
                        <label for="showw" class="show-btn">View Price</label>
                        <div className="prix">
                            <label for="showw" class="close-btn fas fa-times" title="close"></label>
                            <div class="msg">
                                Put the code here
                                <div className="datee">
                                    <input id="BIAT" type="password" required></input>
                                </div>
                                <div class="btn">
                                    <div class="inner"></div>
                                    <button onClick={()=>testerCode(5,"BIAT")}type="submit">check</button>
                                </div>
                                <div className="p">
                                                <p  >{result}</p>
                                            </div>
                            </div>

                        </div></>}
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item-inner">
                    <img src={sarto} alt="" />
                </div>
                <div className="details">
                    <div className="content">

                        <p>Refurbishment and extension of the clean room
                  of the Industrial Site - Administration of the Industrial Site - Extension of the "SARTORIUS Industrial Site
                  STEDIM BIOTECH "{codes.Stedim?<><br/> 38.489,422dt</>:<></>}</p>
                            {codes.Stedim?<></>:<><input type="checkbox" id="shoow" />
                        <label for="shoow" class="show-btn">View Price</label>
                        <div className="prix">
                            <label for="shoow" class="close-btn fas fa-times" title="close"></label>
                            <div class="msg">
                                Put the code here
                                <div className="datee">
                                    <input id="Stedim" type="password" required></input>
                                </div>
                                <div class="btn">
                                    <div class="inner"></div>
                                    <button onClick={()=>testerCode(6,"Stedim")} type="submit">check</button>
                                </div>
                                <div className="p">
                                                <p  >{result}</p>
                                            </div>
                            </div>

                        </div></>}
                    </div>
                </div>
            </div>
            
            <div class="item">
                <div class="item-inner">
                    <img src={baraka} alt="" />
                </div>
                <div className="details">
                    <div className="content">

                        <p>Construction of the new Bank el Baraka headquarters
                  Located at the North Urban Center Lot N B8 Tunis {codes.baraka?<><br/> 5.265.790,108dt</>:<></>}</p>
                            {codes.baraka?<></>:<><input type="checkbox" id="sshow" />
                        <label for="sshow" class="show-btn">View Price</label>
                        <div className="prix">
                            <label for="sshow" class="close-btn fas fa-times" title="close"></label>
                            <div class="msg">
                                Put the code here
                                <div className="datee">
                                    <input id ="baraka" type="password" required></input>
                                </div>
                                <div class="btn">
                                    <div class="inner"></div>
                                    <button onClick={()=>testerCode(8,"baraka")} type="submit">check</button>
                                </div>
                                <div className="p">
                                                <p  >{result}</p>
                                            </div>
                            </div>

                        </div></>}
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item-inner">
                    <img src={hotel} alt="" />
                </div>
                <div className="details">
                    <div className="content">

                        <p>Renovation of the Ilade 4 * hotel in Djerba{codes.Hotel?<><br/> 328.000,000dt</>:<></>}</p>
                            {codes.Hotel?<></>:<><input type="checkbox" id="shooww" />
                        <label for="shooww" class="show-btn">View Price</label>
                        <div className="prix">
                            <label for="shooww" class="close-btn fas fa-times" title="close"></label>
                            <div class="msg">
                                Put the code here
                                <div className="datee">
                                    <input id ="Hotel"  type="password" required></input>
                                </div>
                                <div class="btn">
                                    <div class="inner"></div>
                                    <button onClick={()=>testerCode(9,"Hotel")} type="submit">check</button>
                                </div>
                                <div className="p">
                                                <p  >{result}</p>
                                            </div>
                            </div>

                        </div></>}
                    </div>
                </div>
            </div>
            
            <div class="item">
                <div class="item-inner">
                    <img src={holding} alt="" />
                </div>
                <div className="details">
                    <div className="content">

                        <p>Construction of an office building located at
                  northern urban center TUNIS BUSINESS TOWERS{codes.Business?<><br/> 78.880,000dt</>:<></>}</p>
                            {codes.Business?<></>:<><input type="checkbox" id="ttshow" />
                        <label for="ttshow" class="show-btn">View Price</label>
                        <div className="prix">
                            <label for="ttshow" class="close-btn fas fa-times" title="close"></label>
                            <div class="msg">
                                Put the code here
                                <div className="datee">
                                    <input id ="Business" type="password" required></input>
                                </div>
                                <div class="btn">
                                    <div class="inner"></div>
                                    <button onClick={()=>testerCode(11,"Business")} type="submit">check</button>
                                </div>
                                <div className="p">
                                                <p  >{result}</p>
                                            </div>
                            </div>

                        </div></>}
                    </div>
                </div>
            </div>
          

        </div>
    </section>
    <Footer/>
    </>

    )
}

export default Office
