import React , {useState} from 'react'; 
import './Contact.css'
import axios from 'axios'


function Contact() {
    const [nom,setNom] = useState("")
    const [prenom,setprenom] = useState("")
    const [email,setemail] = useState("")
    const [num,setnum] = useState("")
    const [message,setmessage] = useState("")

    const [result,setResult] = useState("")

    const handleFormSubmit = (e) => {
        e.preventDefault()
        console.log({
            nom,
            prenom,
            email,
            num,
            message
        })

        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: {
                nom,
                prenom,
                email,
                num,
                message
            }
          })
            .then(result => {
              console.log(result)
              setResult(result.data)
              setNom("")
              setprenom("")
              setemail("")
              setnum("")
              setmessage("")
              
            })
            .catch(error => {console.log("oups")});
    }

    const API_PATH = "/contact.php";


    return (
        <div className="cont">
          <div className="container">
            <div className="contactInfo">
                <div>
                    <h2>Contact Info</h2>
                    <ul class='info'>
                        <li>
                            <span><i class="fas fa-map"></i></span>
                            <span> Office N ° 5 BLOC B, 5 BIS <br />
                            Taher ben Ammar Street <br />
                                EL MANAR II TUNIS
                            </span>
                        </li>
                        <li>
                            <span><i class="fas fa-phone"></i></span>
                            <span>22 822 455 – 53 822 455
                            </span>
                        </li>
                        <li>
                            <span><i class="fas fa-envelope"></i></span>
                            <span>Manager@smeee.com.tn<br />
                               Technique@smeee.com.tn <br />
                                Commercial@smeee.com.tn
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <form className="contactForm" onSubmit={handleFormSubmit}>
                <div>
                    <h2>Contact us </h2>
                    <div className="formBox">
                        <div className="inputBox w50">
                            <input type="text"  value={nom} onChange={(e)=>setNom(e.target.value)} name="nom" required />
                            <span>Family name</span>
                        </div>
                        <div className="inputBox w50">
                            <input type="text" value={prenom} onChange={(e)=>setprenom(e.target.value)} type="text" name="pernom" required />
                            <span>Name</span>
                        </div>
                        <div className="inputBox w50">
                            <input type="email" value={email} onChange={(e)=>setemail(e.target.value)} name="email" required />
                            <span>E-mail</span>
                        </div>
                        <div className="inputBox w50">
                            <input type="text" value={num} onChange={(e)=>setnum(e.target.value)} name ="num" required />
                            <span>Phone</span>
                        </div>
                        <div className="inputBox w100">
                            <textarea name ="message" value={message} onChange={(e)=>setmessage(e.target.value)} required></textarea>
                            <span>Type your message here</span>
                        </div>
                        <div className="inputBox w50">
                            <input type="submit" value="send" />
                        </div>
                        <div className="inputBox w50">
                            <p>{result}</p>
                        </div>

                    </div>
                </div>

            </form>
        </div>

    </div>
    )
}

export default Contact
