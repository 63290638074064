import React from 'react'
import './Center.css';
import Sidebar from './Sidebar';
import data from '../images/data.jpg';
import datta from '../images/data1.jpg';
import Navbar from './Navbar';
import Footer from './Footer';


function Center() {
    return (
        <>
        <Navbar/>
            <div id="center" className="cent">
                <Sidebar />
                <div className="contenu">
                    <div className="partone">
                        <div className="imag">
                            <img src={data} alt="" />

                        </div>
                        <div className="tex">
                            <p>Le data center, ou data center en français, désigne un lieu physique où sont regroupés différents équipements informatiques, tels que des ordinateurs, des serveurs, etc. Sa fonction principale est de stocker des informations utiles au fonctionnement normal de l'entreprise. Un centre de données, en fonction de sa taille, des capacités de son système de stockage et d'autres caractéristiques, peut en effet stocker des milliards de clients et de données personnelles.</p>
                        </div>
                    </div>

                    <div className="text">
                        <h2>Data center : architecture et design</h2>

                        <p>En théorie, tout espace suffisamment grand peut être utilisé comme centre de données. Cependant, la conception et la mise en œuvre des centres de données nécessitent quelques précautions. Outre les questions fondamentales de coût et de fiscalité, il existe de nombreux critères de sélection d'un site, tels que la situation géographique, la stabilité météorologique, l'accessibilité des routes et des aéroports, la disponibilité de l'énergie, les télécommunications ou encore l'environnement. politique.

                            Une fois le site sécurisé, vous pouvez vous concentrer sur l'infrastructure électrique et mécanique, ainsi que sur la composition et la disponibilité des équipements informatiques, lors de la conception de l'architecture du datacenter. Toutes ces normes dépendent du tiers du centre de données cible</p>
                    </div>
                    <div className="partone">

                        <div className="tex">
                            <p>L'infrastructure de cloud computing est principalement composée de serveurs situés dans des centres de données. Ils consomment bien sûr de l'énergie, dissipent de la chaleur et nécessitent de l'énergie pour se refroidir. La consommation des datacenters est un problème qui fait l'objet d'une attention croissante et qui est étroitement liée à leur prolifération. De nombreuses études ont tenté de déterminer l'impact énergétique de ces datacenters. Pour les entreprises qui n'ont pas augmenté leur consommation d'énergie mais qui ont doublé en quelques années, il est vital d'établir la consommation des technologies clés. C'est la première étape pour réduire l'impact négatif du centre de données sur l'environnement.</p>
                        </div>
                        <div className="imag">
                            <img src={datta} alt="" />

                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Center
