import React ,{ useState , useEffect} from 'react'
import { Button } from './Button';
import Logo from "../images/logo.png";

import './Navbar.css';
import  french from '../images/french.png'; 
import english from'../images/english.png'; 
import { HashLink } from 'react-router-hash-link';



function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);


    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
          setButton(false);
        } else {
          setButton(true);
        }
      };
    
      useEffect(() => {
        showButton();
      }, []);
    
      window.addEventListener('resize', showButton);

    return (
    <>
        <nav className="navbar">
          <div className='navbar-container'>
          <HashLink smooth to='/#hero' className='navbar-logo' onClick={closeMobileMenu}>
            <img src={Logo} alt="Logo" />
          </HashLink>
          <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <HashLink smooth to='/#hero' className='nav-links' onClick={closeMobileMenu}>
                Acceuil
              </HashLink>
            </li>
            <li className='nav-item'>
              <HashLink smooth
                to='/propos#tete'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                A propos
              </HashLink>
            </li>

            <li className='nav-item'>
              <HashLink smooth
                to='/services/#center'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Services
              </HashLink>
            </li>
            
            <li className='nav-item'>
              <p
                className='nav-links'
              >
                Réferences<i class="fas fa-caret-down"></i>
                <ul>
                  <li><HashLink smooth to='/immeuble#Immeuble-à-usage-bureautique'  className='nav-links'  onClick={closeMobileMenu}>Immeuble à usage bureautique </HashLink></li>
                  <li><HashLink smooth to='/data#data'  className='nav-links'  onClick={closeMobileMenu}>Data center</HashLink></li>
                  <li><HashLink smooth to='/industrie#industrie'   className='nav-links' onClick={closeMobileMenu}>Industrie </HashLink></li>
                  <li><HashLink smooth to='/ge#groupe' className='nav-links'  onClick={closeMobileMenu}>Poste transformation et GE</HashLink> </li>
               </ul>

                </p>
            </li>
            


            <li>
              <HashLink
                to='/contact'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
               Contact
              </HashLink>
            </li>
            <li className='nav-item' >
            <HashLink to ="/"   className="img" onClick={closeMobileMenu} ><img src={french}  alt=""/></HashLink>
            <HashLink to="/home" className="img" onClick={closeMobileMenu}> <img src={english}  alt=""/></HashLink>
            </li>
          </ul>
         
          <HashLink to='/contact'>
          {button  &&  <Button  buttonStyle='btn--outline'>Contact</Button> }
          </HashLink>

        </div>

        </nav>
    </>
    )
}

export default Navbar;
