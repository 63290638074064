import React from 'react'
import './Partenaires.css'
import Elec from '../images/elec.jpg'
import 'swiper/swiper.scss';
import baraka from '../images/baraka.png';
import global from '../images/globaltech.png'; 
import tower from '../images/holding.png';



function Partenaires() {
    return (
        <div className="one" style={{ backgroundImage: `url(${Elec})` }}>
          <h4>Our<br/> partners </h4>
           
      <div class="slider">
        <div class="images">
            <input type="radio" name="slide" id="img1"  />
            <input type="radio" name="slide" id="img2" />
            <input type="radio" name="slide" id="img3" />

            

            <img src={tower} class="m1" alt="img1" />
            <img src={global} class="m2" alt="img2"/>
            <img src={baraka} class="m3" alt="img3"/>

            
        </div>
        <div class="dots">
            <label for="img1"></label>
            <label for="img2"></label>
            <label for="img3"></label>

            
        </div>
    </div>
        </div>
    )
}

export default Partenaires;
