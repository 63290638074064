import React , {useState} from 'react'
import './Data.css'
import '../../App.css';
import Footer from './Footer';
import Navbar from './Navbar';

import lncm from '../images/lncm.png'


import tsb from '../images/tsb.png';
import Fond from '../images/fond.jpg';
import uib from '../images/UIB.jpg';
import onas from '../images/onas.jpg';
import tb from '../images/TB.png';
import bh from '../images/bh.png';
import menix from '../images/menix.png';
import enda from '../images/enda.png';
import baraka from '../images/baraka.png'


import axios from 'axios';

function Data() {
    const [codes, setCodes] = useState({
        Mall: false,
        Transcom: false,
        LNCM: false,
        ONH: false,
        BIAT: false,
        Stedim: false,
        UG: false,
        baraka: false,
        Hotel: false,
        Volvo: false,
        Business: false,
        Misfat: false,
        TSB: false,
        Onas: false,
        UIB: false,
        BT: false,
        BH: false,
        Meninix: false,
        Enda: false,
        Safia: false,
        Protein: false,
        Ennasim: false,
        baraka01: false,
        Misfat01: false,
        LNCM01: false,
        Stedim01: false,
        Transcom01: false,
        UG01: false,
        baraka02: false,
        Marsa: false,

    })
    const [result,setResult] = useState("")

    const testerCode = (id, name) => {
        console.log(document.getElementById(name).value)
        const API_PATH = "/testerCode.php"
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: {
                idSociete: id,
                password: document.getElementById(name).value
            }
        })
            .then(result => {
                console.log(result)
                setResult(result.data)

                if (result.data == "update done") {
                    setCodes({ ...codes, [name]: true })
                    console.log(codes)
                    console.log("changing")
                }

                
            })
            .catch(error => { console.log("oups") });
    }
    return (
        <>
        <Navbar/>
        <section id="data-center" className="contai" style={{ backgroundImage: `url(${Fond})` }}>
        <h1>Data center </h1>
        <div className="row">
            <div className="photo">
                <div className="cad">
                    <img src={tsb} alt="" />
                </div>
                <div className="cad-text">
                    <p>Development  <br /> work <br />{codes.TSB?<><br/> 240.000,000dt</>:<></>}</p><br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            {codes.TSB?<></>:<><input type="checkbox" id="ab" />
                            
                    <label for="ab" class="show-btn">View Price</label>
                    <div className="prix">
                        <label for="ab" class="close-btn fas fa-times" title="close"></label>
                        <div class="msg">
                            Put the code here
                            <div className="datee">
                                <input id ="TSB"  type="password" required></input>
                            </div>
                            <div class="btn">
                                <div class="inner"></div>
                                <button onClick={()=>testerCode(13,"TSB")} type="submit">check</button>
                            </div>
                            <div className="p">
                                <p  >{result}</p>
                                </div>
                        </div>

                    </div></>}

                </div>

            </div>
            <div className="photo">
                <div className="cad">
                    <img src={onas} alt="" />
                </div>
                <div className="cad-text">
                    <p>Development  <br /> work <br /> <br />{codes.Onas?<><br/> 140.000,000dt</>:<></>}</p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                            {codes.Onas?<></>:<><input type="checkbox" id="ac" />
                    <label for="ac" class="show-btn">View Price</label>
                    <div className="prix">
                        <label for="ac" class="close-btn fas fa-times" title="close"></label>
                        <div class="msg">
                            Put the code here
                            <div className="datee">
                                <input id ="Onas" type="password" required></input>
                            </div>
                            <div class="btn">
                                <div class="inner"></div>
                                <button onClick={()=>testerCode(14,"Onas")} type="submit">check</button>
                            </div>
                            <div className="p">
                                <p  >{result}</p>
                                </div>
                        </div>

                    </div></>}

                </div>

            </div>
            <div className="photo">
                <div className="cad">
                    <img src={uib} alt="" />
                </div>
                <div className="cad-text">
                    <p>Development  <br /> work<br /><br /><br /> <br /> <br />{codes.UIB?<><br/> 365.000,000dt</>:<></>}</p>
                            {codes.UIB?<></>:<><input type="checkbox" id="ad" />
                    <label for="ad" class="show-btn">View Price</label>
                    <div className="prix">
                        <label for="ad" class="close-btn fas fa-times" title="close"></label>
                        <div class="msg">
                            Put the code here
                            <div className="datee">
                                <input id ="UIB" type="password" required></input>
                            </div>
                            <div class="btn">
                                <div class="inner"></div>
                                <button onClick={()=>testerCode(15,"UIB")} type="submit">check</button>
                            </div>
                            <div className="p">
                                <p  >{result}</p>
                                </div>
                        </div>

                    </div></>}

                </div>

            </div>
            <div className="photo">
                <div className="cad">
                    <img src={tb} alt="" />
                </div>
                <div className="cad-text">
                    <p>Development  <br /> work<br /><br /><br /> <br /> <br />{codes.BT?<><br/> 280.000,000dt</>:<></>}</p>
                            {codes.BT?<></>:<><input type="checkbox" id="ae" />
                    <label for="ae" class="show-btn">View Price</label>
                    <div className="prix">
                        <label for="ae" class="close-btn fas fa-times" title="close"></label>
                        <div class="msg">
                            Put the code here
                            <div className="datee">
                                <input id ="BT" type="password" required></input>
                            </div>
                            <div class="btn">
                                <div class="inner"></div>
                                <button onClick={()=>testerCode(16,"BT")} type="submit">check</button>
                            </div>
                            <div className="p">
                                <p  >{result}</p>
                                </div>
                        </div>

                    </div></>}

                </div>

            </div>
            <div className="photo">
                <div className="cad">
                    <img src={baraka} alt="" />
                </div>
                <div className="cad-text">
                    <p>Development  <br /> work<br />{codes.baraka01?<><br/> 78.880,000dt</>:<></>}</p>
                    <br /> <br /> <br /> <br /> <br />
                            {codes.baraka01?<></>:<><input type="checkbox" id="af" />
                    <label for="af" class="show-btn">View Price</label>
                    <div className="prix">
                        <label for="af" class="close-btn fas fa-times" title="close"></label>
                        <div class="msg">
                            Put the code here
                            <div className="datee">
                                <input id ="baraka01" type="password" required></input>
                            </div>
                            <div class="btn">
                                <div class="inner"></div>
                                <button onClick={()=>testerCode(8,"baraka01")} type="submit">check</button>
                            </div>
                            <div className="p">
                                <p  >{result}</p>
                                </div>
                        </div>

                    </div></>}

                </div>

            </div>


            <div className="photo">
                <div className="cad">
                    <img src={lncm} alt="" />
                </div>
                <div className="cad-text">
                    <p>Development  <br /> work<br /><br /><br /> <br /> <br />{codes.LNCM01?<><br/> 320.000,000dt</>:<></>}</p>
                            {codes.LNCM01?<></>:<><input type="checkbox" id="ag" />
                    <label for="ag" class="show-btn">View Price</label>
                    <div className="prix">
                        <label for="ag" class="close-btn fas fa-times" title="close"></label>
                        <div class="msg">
                            Put the code here
                            <div className="datee">
                                <input id ="LNCM01" type="password" required></input>
                            </div>
                            <div class="btn">
                                <div class="inner"></div>
                                <button onClick={()=>testerCode(3,"LNCM01")} type="submit">check</button>
                            </div>
                            <div className="p">
                                <p  >{result}</p>
                                </div>
                        </div>

                    </div></>}

                </div>

            </div>

            <div className="photo">
                <div className="cad">
                    <img src={bh} alt="" />
                </div>
                <div className="cad-text">
                    <p>Development  <br /> work<br /><br /><br /> <br /> <br />{codes.BH?<><br/> 224.000,000dt</>:<></>}</p>
                            {codes.BH?<></>:<> <input type="checkbox" id="ak" />
                    <label for="ak" class="show-btn">View Price</label>
                    <div className="prix">
                        <label for="ak" class="close-btn fas fa-times" title="close"></label>
                        <div class="msg">
                            Put the code here
                            <div className="datee">
                                <input id ="BH" type="password" required></input>
                            </div>
                            <div class="btn">
                                <div class="inner"></div>
                                <button onClick={()=>testerCode(17,"BH")} type="submit">check</button>
                            </div>
                            <div className="p">
                                <p  >{result}</p>
                                </div>
                        </div>

                    </div></>}

                </div>

            </div>

            <div className="photo">
                <div className="cad">
                    <img src={menix} alt="" />
                </div>
                <div className="cad-text">
                    <p>Upgrade<br /><br /> <br /><br /><br /> <br /> <br />{codes.Meninix?<><br/> 480.000,000dt</>:<></>}</p>
                            {codes.Meninix?<></>:<><input type="checkbox" id="al" />
                    <label for="al" class="show-btn">View Price</label>
                    <div className="prix">
                        <label for="al" class="close-btn fas fa-times" title="close"></label>
                        <div class="msg">
                            Put the code here
                            <div className="datee">
                                <input id ="Meninix" type="password" required></input>
                            </div>
                            <div class="btn">
                                <div class="inner"></div>
                                <button onClick={()=>testerCode(18,"Meninix")} type="submit">check</button>
                            </div>
                            <div className="p">
                                <p  >{result}</p>
                                </div>
                        </div>

                    </div></>}

                </div>

            </div>
            <div className="photo">
                <div className="cad">
                    <img src={enda} alt="" />
                </div>
                <div className="cad-text">
                    <p>Development <br /> work<br /><br /><br /> <br /> <br />{codes.Enda?<><br/> 180.000,000dt</>:<></>}</p>
                            {codes.Enda?<></>:<> <input type="checkbox" id="am" />
                    <label for="am" class="show-btn">View Price</label>
                    <div className="prix">
                        <label for="am" class="close-btn fas fa-times" title="close"></label>
                        <div class="msg">
                            Put the code here
                            <div className="datee">
                                <input id ="Enda" type="password" required></input>
                            </div>
                            <div class="btn">
                                <div class="inner"></div>
                                <button onClick={()=>testerCode(19,"Enda")} type="submit">check</button>
                            </div>
                            <div className="p">
                                <p  >{result}</p>
                                </div>
                        </div>

                    </div></>}

                </div>

            </div>


        </div>

    </section>
    <Footer/>
    </>

    )
}

export default Data
