import './App.css';
import Acceuil from './components/pages/Acceuil';
import Contactt from './components/pages/Contactt'
import Services from './components/pages/Services';
import Propos from './components/pages/Propos'
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './En/components/pages/Acceuil';
import about from './En/components/pages/Propos';
import service from './En/components/pages/Services';
import Contact from './En/components/pages/Contactt';
import Center from './components/Center';
import Faible from './components/Faible';
import Fort from './components/Fort';
import Systeme from './components/Systeme';
import Electro from './components/Electro';
import Trans from './components/Trans';
import Centerr from './En/components/Center';
import Faiblee from './En/components/Faible';
import Fortt from './En/components/Fort';
import Systemee from './En/components/Systeme';
import Electroo from './En/components/Electro';
import Transs from './En/components/Trans';
import Login from './components/pages/Login';
import Code from './components/pages/Code';
import Bureau from './components/Bureau';
import { useState } from 'react';
import Data from './components/Data';
import Ge from './components/Ge';
import Industrie from './components/Industrie';
import Office from './En/components/Office';
import Datta from './En/components/Data';
import GEE from './En/components/Ge';
import Industry from './En/components/Industry';
function App() {

  const [loggedIn,setLoggedIn] = useState(false)
  return (
    <>
      <Router>
        <Switch>
          <Route path='/' exact component={Acceuil} />
          <Route path='/contact' exact component={Contactt} />
          <Route path='/industrie' exact component={Industrie} />
          <Route path='/propos' exact component={Propos} />
          <Route path='/services' exact component={Services} />
          <Route path='/home' exact component={Home} />
          <Route path='/contactt' exact component={Contact} />
          <Route path='/industry' exact component={Industry} />
          <Route path='/about' exact component={about} />
          <Route path='/service' exact component={service} />
          <Route path='/ge' exact component={Ge} />
          <Route path='/gee' exact component={GEE} />

          <Route path='/office' exact component={Office} />



          <Route path='/data' exact component={Data} />
          <Route path='/dataa' exact component={Datta} />


          <Route path='/immeuble' exact component={Bureau} />

          <Route path ='/login' render={(props) => <Login setLoggedIn={setLoggedIn} {...props} />} exact/> 
          <Route path ='/code' exact render={(props) => <Code loggedIn={loggedIn} {...props} />}/>



          <Route path='/services/' exact component={Center} />
          <Route path='/faible' exact component={Faible} />
          <Route path='/fort' exact component={Fort} />
          <Route path='/securité' exact component={Systeme} />
          <Route path='/transformation' exact component={Trans} />
          <Route path='/electrogéne' exact component={Electro} />


          <Route path='/service/center' exact component={Centerr} />
          <Route path='/faible' exact component={Faiblee} />
          <Route path='/fort' exact component={Fortt} />
          <Route path='/securité' exact component={Systemee} />
          <Route path='/transformation' exact component={Transs} />
          <Route path='/electrogéne' exact component={Electroo} />











        </Switch>


      </Router>
    </>
  );
}

export default App;
