import React ,{useState}from 'react';
import '../../App.css';
import Footer from './Footer';

import Navbar from './Navbar';
import Mall from '../../images/mall.png'

import onh from '../../images/onh.png'

import volvo from '../../images/volvo.png'
import misfat from '../../images/misfat.png'
import sarto from '../../images/sarto.png'

import next from '../../images/next.png'
import ug from '../../images/utique.png';

import tom from '../../images/TOM.png';


import './Industry.css';


function Industry() {
  




   

 

  return (
    <>
      <Navbar />
     
      
   
    <section id="industrie" className="indus">
    <h1>Industry</h1>
                <div className="indust">
                    <div className="picture">
                        <img src={misfat} alt="" />

                    </div>
                    <div className="picture">
                        <img src={Mall} alt="" />

                    </div>
                    <div className="picture">
                        <img src={sarto} alt="" />

                    </div>
                    <div className="picture">
                        <img src={volvo} alt="" />

                    </div>
                    <div className="picture">
                        <img src={onh} alt="" />

                    </div>
                    <div className="picture">
                        <img src={ug} alt="" />

                    </div>
                    <div className="picture">
                        <img src={next} alt="" />

                    </div>
                    <div className="picture">
                        <img src={tom} alt="" />

                    </div>
                </div>




    </section>

    
      <Footer />

    </>
  );
}

export default Industry;
