import React from 'react';
import './Trans.css';
import poste from '../images/poste.jpg';
import poste1 from '../images/poste1.jpg';
import Sidebarr from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';



function Trans() {
    return (
        <>
        <Navbar/>

        <div className="trans">
            <Sidebarr />
            <div className="contenu">


                <div className="partone">
                    <div className="imag">
                        <img src={poste} alt="" />

                    </div>
                    <div className="tex">
                        <p>A transformer station is an element of the electrical network that transmits and distributes electricity.

                            <br /> It raises the electrical voltage for transmission of the latter, then it lowers it so that it can be consumed by users. The electricity that arrives at a private individual first passes through a transformer station, otherwise known as a source station.
                        </p>
                    </div>
                </div>
                <div className="partone">

                    <div className="tex">
                        <p>Most of the time, the transformer substation is fitted with protective equipment such as the circuit breaker, with which it is possible to cut off the current. This protection can be a surge arrester which protects the transformer substation from any overvoltages produced by lightning. There are several transformer stations, depending on the KVA (kilovoltampere). Transformer substations are one of the two main categories of high voltage substations.</p>
                    </div>
                    <div className="imag">
                        <img src={poste1} alt="" />

                    </div>
                </div>


            </div>
        </div>
        <Footer />

</>

    );
}

export default Trans;
