import React from 'react';
import './Fort.css';
import four from '../images/4.png';
import five from'../images/5.jpg';
import six from '../images/6.png';
import seven from '../images/7.jpg'; 
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';



function Fort() {
    return (
        <>
                        <Navbar/>

        <div className="for">
                                    <Sidebar />
           <div className="contenu">
            <div className="texts">
                <p>La fonction du courant fort consiste à transporter l’énergie à travers le réseau électrique. Son utilisation permet d’alimenter les sources de lumières, les prises de courant, les systèmes de climatisation et chauffage nécessitant de fortes intensité électriques.<br/>

                    Un courant fort a une intensité de quelques centaines de mA à plusieurs kA.</p>
            </div>
            <div className="partone">
                    <div className="imag">
                        <img src={four} alt="" />
   
                    </div>
                    <div className="tex">
                        <p>Le courant fort arrive dans le logement par le compteur d’électricité. Il est ensuite acheminé jusqu’au tableau électrique. Dans le tableau électrique, chaque circuit est défini et protégé par un disjoncteur. Ces circuits peuvent représenter les pièces de la maison, les prises, les éclairages ou l’électroménager. Chaque logement doit posséder au moins 4 circuits électriques différents. Le courant fort est ensuite acheminé par des câbles jusqu’aux prises et aux interrupteurs de l’appartement ou de la maison.</p>
                    </div>
                   
            </div>
            <div className="imagess">
                <img src={ five} alt =""/>
                
                <img src={ seven} alt =""/>
                <img src={ six} alt =""/>

                

            </div>

        </div>
        </div>
        <Footer />

        </>
    )
}

export default Fort
