import React from 'react';
import './Trans.css';
import poste from '../images/poste.jpg';
import poste1 from '../images/poste1.jpg';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';


function Trans() {
    return (
        <>
                           <Navbar/>

        <div className="trans">
                    <Sidebar />

            <div>
            <div className="partone">
                <div className="imag">
                    <img src={poste} alt="" />

                </div>
                <div className="tex">
                    <p>Un poste de transformation est un élément du réseau électrique qui transmet et distribue l'électricité.

                        <br />Il élève la tension électrique en vue de la transmission de cette dernière, puis il la fait redescendre afin qu'elle puisse être consommée par les usagers. L'électricité qui arrive chez un particulier passe tout d'abord par un poste de transformation, autrement nommé poste source.
                    </p>
                </div>
            </div>
            <div className="partone">

                <div className="tex">
                    <p>La plupart du temps, le poste de transformation est doté d'un équipement de protection comme le disjoncteur, avec lequel il est possible de couper le courant. Cette protection peut être un parafoudre qui protège le poste de transformation d'éventuelles surtensions produites par la foudre. Il existe plusieurs postes de transformation, selon le KVA (kilovoltampère). Les postes de transformation sont l'une des deux grandes catégories de postes haute tension.</p>
                </div>
                <div className="imag">
                    <img src={poste1} alt="" />

                </div>
            </div>


        </div>
        </div>
        <Footer />

        </>
    );
}

export default Trans;
