import React , {useState}from 'react';
import './Body.css';
import el from '../images/elecc.jpg';
import baraka from '../images/baraka.png';
import bh from '../images/bh.png';
import biat from '../images/biat.png';
import { Link } from 'react-router-dom';
import rec from '../images/reclamtion.png';
import axios from 'axios'

function Body() {




    const [email,setemail] = useState("")
    const [message,setmessage] = useState("")

    const [result,setResult] = useState("")

    const handleFormSubmit = (e) => {
        e.preventDefault()
        console.log({
            email,
            message
        })
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: {
                
                email,
                message
            }
          })
            .then(result => {
              console.log(result)
              setResult(result.data)
              setemail("")
              setmessage("")
            })
            .catch(error => {console.log("oups")});
    }
    const API_PATH = "/reclamation.php";

    return (
        <div className="body">
            <div className="apropos-text">
                <h3>Who are we ? </h3>
                <p> SMEEE is a young company which has achieved success 
                    in the field of electrical installation in Tunisia since its creation 
                    in 2014 by <strong> Mr. Haddad Zouhair.</strong> It designs the study and 
                    execution of electrical work in the building and industrial sectors meeting 
                    the international standard NFC 15-1 Thus, it ensures industrial maintenance thanks
                     to its qualified staff composed of technicians and engineers specializing in various
                      fields such as industrial IT, automation and fire detection.<br/>
                     <br/> <br/> <strong> SMEEE is also one of the 257 companies approved by the
                          competent authorities by STEG <a href="https://www.steg.com.tn/dwl/Entreprises_Agrees.pdf"> (See line 134 in the table ) </a>
                     </strong> </p>
            </div>

            <div className="qualite">
                <div className="quality">
                    <div className="icon">
                        <i class="fas fa-cogs"></i>
                    </div>
                    <h3>Quality work </h3>
                    <p>Our experience and our know-how make us
                         allow to accompany you in
                         project development from the start
                         from the project to the execution phase
                         and achievement. 
                    </p>
                </div>
                <div className="quality">
                    <div className="icon">
                        <i class="fas fa-user-friends"></i>
                    </div>
                    <h3>Customer satisfaction</h3>
                    <p>With our objectives set since our launch,
                         we always try to show you
                         that we put your satisfaction
                         at the heart of our priorities. And thanks to your recommendation
                         and loyalty, we increase together. 
                    </p>
                </div>
                <div className="quality">
                    <div className="icon">
                    <i class="fas fa-lightbulb"></i>
                    </div>
                    <h3>Integrity & Innovation </h3>
                    <p>Integrity is about communicating
                          honestly for c
                          create an atmosphere of trust and respect.
                          We therefore place innovation at the heart of our actions
                          , because on the market, our reactivity
                           is a necessary and important asset 
                    </p>
                </div>
            </div>
            <div className="ph" style={{ backgroundImage: `url(${el})` }}></div>
            <div className="horraire">
                <i class="far fa-clock"> Opening time </i>
                <div className="time">
                    <div className="tim normal">
                        <h4> Everyday</h4>
                        <div className="days">
                            <div className="day">
                                <h6>Monday</h6>
                                <p>08:30-17:00</p>
                            </div>
                            <div className="day">
                                <h6>Tuesday</h6>
                                <p>08:30-17:00</p>
                            </div>
                            <div className="day">
                                <h6>Wednesday</h6>
                                <p>08:30-17:00</p>
                            </div>
                            <div className="day">
                                <h6>Thusday</h6>
                                <p>08:30-17:00</p>
                            </div>
                            <div className="day">
                                <h6>Friday</h6>
                                <p>08:30-17:00</p>
                            </div>
                            <div className="day">
                                <h6>Saturday</h6>
                                <p className="ferm">Fermé</p>
                            </div>
                            <div className="day">
                                <h6>Sunday</h6>
                                <p className="ferm">Fermé</p>
                            </div>
                        </div>
                    </div>
                    <div className="tim ete">
                        <h4>Summer</h4>
                        <div className="days">
                        <div className="day">
                                <h6>Monday</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Tuesday</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Wednesday</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Thusday</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Friday</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Saturday</h6>
                                <p className="ferm">Fermé</p>
                            </div>
                            <div className="day">
                                <h6>Sunday</h6>
                                <p className="ferm">Fermé</p>
                            </div>
                        </div>
                    </div>
                    <div className="tim ramadan">
                        <h4>Ramadan month</h4>
                        <div className="days">
                        <div className="day">
                                <h6>Monday</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Tuesday</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Wednesday</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Thusday</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Friday</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Saturday</h6>
                                <p className="ferm">Fermé</p>
                            </div>
                            <div className="day">
                                <h6>Sunday</h6>
                                <p className="ferm">Fermé</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div className="work">
                <div className="picture">
                    <img src={baraka} alt="" />

                </div>
                <div className="picture">
                    <img src={bh} alt="" />

                </div>
                <div className="picture">
                    <img src={biat} alt="" />

                </div>
                <div className="picture">
                    <Link to='/references'>
                        <p>More </p>
                    </Link>
                </div>
            </div>
            <div className="reclamtion">
                <div className="recltext">
                    <h4>For all types of complaints...</h4>


                </div>
                <img src={rec} alt="" />
                <div className="reclForm">
                    <form onSubmit={handleFormSubmit}>
                        <div className="inputBox w100">
                            <input type="email" value={email} onChange={(e)=>setemail(e.target.value)} name="email" required />
                            <span>E-mail</span>
                        </div>
                        <div className="inputBox w100">
                            <textarea name="message" value={message} onChange={(e)=>setmessage(e.target.value)} required></textarea>
                            <span>Type your message here </span>
                        </div>
                        <div className="inputBox w100">
                            <input type="submit" value="Send" />
                        </div>
                    </form>
                </div>

            </div>
            <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.15696032061!2d10.154981250212941!3d36.83871677313305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd332371a864df%3A0x16a4c1cc93498805!2sSMEEE%20(%20St%C3%A9%20Malek%20Etude%20Ex%C3%A9cution%20Electricit%C3%A9%20)!5e0!3m2!1sfr!2stn!4v1631553110726!5m2!1sfr!2stn" ></iframe>

            </div>

        </div>
    );
}

export default Body;
