import React from 'react'
import './Faible.css';
import one from '../images/1.jpg'
import two from '../images/2.jpg'
import three from '../images/3.jpg'
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';




function Faible() {
    return (
        <>
                <Navbar/>

        <div className="faib">
                        <Sidebar />

            <div className="contenu">
            <div className="texts">
                <p>On appelle "courant faible" un réseau filaire qui transmet des informations mais permet aussi de communiquer : réseau filaire, antenne au sol, antenne satellite, réseau audiovisuel, appareil multimédia (TV, home cinéma...), téléphone, domotique, walkie- talkie, vidéosurveillance, alarme, Ethernet (diffusion Internet filaire, pas WiFi).</p>

            </div>
            <div className="imagess">
                <img src={ one} alt =""/>
                <img src={ two} alt =""/>

                <img src={ three} alt =""/>


            </div>
            <div className="textdeux">
                <p>Dans les installations de courants faibles, il est possible de trouver : </p>
                <ul>
                    <li>Détecteurs de flammes</li>
                    <li>Alarmes antivol</li>
                    <li>Détecteurs de fumée</li>
                    <li>Détecteurs de mouvement</li>
                    <li>Caméras de vidéosurveillance. CCTV.</li>
                    <li>Ordinateurs</li>
                    <li>Téléphones</li>
                    <li>Fibre optique</li>
                    <li>Système de communication par haut-parleurs</li>

                </ul>

            </div>

        </div>
     
        </div>
        <Footer />


</>    )
}

export default Faible
