import React from 'react';
import './Footer.css';
import { HashLink } from 'react-router-hash-link';


function Footer() {
  return (
    <footer>
    <div className="main-content">
      <div class="left box">
        <h2>Contact details </h2>
        <div class="content">
          <div class="place">
            <span class="fas fa-map-marker-alt"></span>
            <span class="text">Office N ° 5 BLOCK B,
               5 BIS Rue Taher ben Ammar
               EL MANAR II TUNIS</span>
          </div>
          <div class="phone">
            <span class="fas fa-phone-alt"></span>
            <span class="text">22 822 455 – 53 822 455</span>
          </div>
          <div class="email">
            <span class="fas fa-envelope"></span>
            <span class="text">Manager@smeee.com.tn - 
            Technique@smeee.com.tn -
            Commercial@smeee.com.tn</span>
          </div>
        </div>

      </div>
      <div class="center box">
        <h2>Navigation</h2>
        <div class="content">
          <ul>
            <HashLink smooth to='/#hero'><li>Home</li></HashLink>
            <HashLink smooth to='/about#tete'><li>About</li></HashLink>
            <HashLink smooth to='/service/#center'><li>Services</li></HashLink>
            <HashLink smooth to='/reference#first'><li>References</li></HashLink>
            <HashLink smooth to='/contact'><li>Contact</li></HashLink>
          </ul>
        </div>

      </div>
      <div class="right box">
        <h2>Location</h2>
         <div class="content">
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.15696032061!2d10.154981250212941!3d36.83871677313305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd332371a864df%3A0x16a4c1cc93498805!2sSMEEE%20(%20St%C3%A9%20Malek%20Etude%20Ex%C3%A9cution%20Electricit%C3%A9%20)!5e0!3m2!1sfr!2stn!4v1631553110726!5m2!1sfr!2stn" ></iframe>
         </div>


      </div>


    </div>
    </footer>
  )
}

export default Footer;
