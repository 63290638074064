import React from 'react';
import './Electro.css';
import electro from "../images/elect.jpg";
import elect from "../images/elect1.jpg";
import Sidebarr from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';

function Electro() {
    return (
        <>
        <Navbar/>
        <div className="electro">
            <Sidebarr />
            <div className="contenu">
                <div className="texts">
                    <p>A generator set is the combination of a machine producing mechanical energy and an alternator. An electricity production plant is in fact a “generator set coupled to a“ boiler ””. <br /> <br /> For a generator or alternators to work, you need speed regulation on the engine and voltage regulation on the alternator. In addition, they must be equipped with a set of sensors and safety devices. Finally, they are equipped with a start and stop management system which can be manual or automatic </p>
                </div>
                <div className="partone">
                    <div className="imag">
                        <img src={elect} alt="" />

                    </div>
                    <div className="tex">
                        <p> As we have seen previously, there are different categories of people likely to use generators. Depending on whether these are specific or professional needs. On the professional level, the uses are also diverse according to the needs and the circumstances. For example, a hospital should have a backup generator to replace the main source. It must be of significant power in view of the energy consumption observed in this type of environment. </p>
                    </div>

                </div>
                <div className="partone">

                    <div className="tex">
                        <p>The generator set is used for energy production, supplying a distribution network. This type of installation finds its application on sites where the public distribution network is non-existent, and technically or economically impossible to set up, such as in the mountains, or on an island. The annual running time of these generators is often very long. </p>
                    </div>
                    <div className="imag">
                        <img src={electro} alt="" />

                    </div>
                </div>
            </div>
        </div>
        <Footer />

</>

    )
}

export default Electro
