import React from 'react';
import { Button } from './Button';
import { HashLink, NavHashLink } from 'react-router-hash-link';


function Ref() {
    return (
        <section id ="first"className='pricing__section' >
        <div className='pricing__wrapper'>
          <div className='pricing__container'>
            <div className='pricing__container-card'>
              <div className='pricing__container-cardInfo'>
                <div className='icon'>
                <i class="fas fa-building"></i>    
                </div>
                <h3>Office building  </h3>
                <ul>
                  <li> - El baraka</li>
                  <li> - LNCM</li>
                  <li> - Transcom</li>
                  <li> - Dar El Marsa</li>

                </ul>
                <NavHashLink to="/office#Office-building" ><Button buttonSize='btn--wide' buttonColor='primary'>
                  Plus
                </Button> </NavHashLink>
              </div>
            </div>
            <div className='pricing__container-card'>
              <div className='pricing__container-cardInfo'>
                <div className='icon'>
                  <i class="fas fa-desktop"></i>
                </div>
                <h3>Data center </h3>
                <ul>
                  <li> - UIB</li>
                  <li> - BH </li>
                  <li> - ONAS</li>
                  <li> - LNCM ...</li>
                </ul>

                <NavHashLink to="/dataa#data-center"><Button buttonSize='btn--wide' buttonColor='blue'>
                  Plus
                </Button></NavHashLink>
              </div>
            </div>
            <div className='pricing__container-card'>
              <div className='pricing__container-cardInfo'>
                <div className='icon'>
                  <i class="fas fa-redo"></i>
                </div>
                <h3>Transformer station and Generating sets</h3>
                <ul>
                <li> - El baraka</li>
                                    <li> - Misfat</li>
                                    <li> - Sartorius stedim...</li>
                </ul>
                <NavHashLink to="/gee#transformation"><Button buttonSize='btn--wide' buttonColor='primary'>
                  Plus
                </Button></NavHashLink>
              </div>
            </div>
            <div className='pricing__container-card'>
              <div className='pricing__container-cardInfo'>
                <div className='icon'>
                <i class="fas fa-industry"></i>
                </div>
                <h3>Industry </h3>
                <ul>
                <li> - Mall of sousse</li>
                                    <li> - Volvo</li>
                                    <li> - Misfat </li>
                                    <li> -  Utique Gaz ...</li>
                </ul>
                <NavHashLink to="/industry#industrie"><Button buttonSize='btn--wide' buttonColor='primary'>
                  Plus
                </Button></NavHashLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default Ref
