import React, { useState } from 'react'
import './Ge.css'
import Trans from '../images/TRANSCOM.png'
import Footer from './Footer';
import Navbar from './Navbar';
import '../App.css';


import baraka from '../images/baraka.png'

import misfat from '../images/misfat.png'
import sarto from '../images/sarto.png'

import fond from '../images/fonf.jpg';
import next from '../images/next.png'
import ug from '../images/utique.png';
import safia from '../images/safia.png';
import ennasim from '../images/ennasim.png';
import axios from 'axios';


function Ge() {
    const [codes, setCodes] = useState({
        Mall: false,
        Transcom: false,
        LNCM: false,
        ONH: false,
        BIAT: false,
        Stedim: false,
        UG: false,
        baraka: false,
        Hotel: false,
        Volvo: false,
        Business: false,
        Misfat: false,
        TSB: false,
        Onas: false,
        UIB: false,
        BT: false,
        BH: false,
        Meninix: false,
        Enda: false,
        Safia: false,
        Protein: false,
        Ennasim: false,
        baraka01: false,
        Misfat01: false,
        LNCM01: false,
        Stedim01: false,
        Transcom01: false,
        UG01: false,
        baraka02: false,
        Marsa: false,

    })
    const [result, setResult] = useState("")

    const testerCode = (id, name) => {
        console.log(document.getElementById(name).value)
        const API_PATH = "/testerCode.php"
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: {
                idSociete: id,
                password: document.getElementById(name).value
            }
        })
            .then(result => {
                console.log(result)
                setResult(result.data)
                if (result.data == "update done") {
                    setCodes({ ...codes, [name]: true })
                    console.log(codes)
                    console.log("changing")
                }
            })
            .catch(error => { console.log("oups") });
    }
    return (
        <>
            <Navbar />
            <section id="groupe" className="gr" style={{ backgroundImage: `url(${fond})` }}>
                <h1>Poste de transformation & groupes électrogénes  </h1>
                <div className="grp" >

                    <div class="card middle">
                        <div class="front">
                            <img src={baraka} alt="" />
                        </div>
                        <div class="back">
                            <div class="back-content middle">
                                <span>3 Groupes Electrogénes : <br />800KVA <br />350KVA<br />220KVA <br />
                                    - Construction du nouveau siège
                                    Bank el Baraka Sis au Centre
                                    Urbain Nord Lot N B8 Tunis-Poste de transformation
                                    prive2x1000kvaSEC <br /> <br />{codes.baraka02 ? <><br /> 390.000,000dt</> : <></>}</span>
                                {codes.baraka02 ? <></> : <><input type="checkbox" id="a" />
                                    <label for="a" class="show-btn">Voir prix</label>
                                    <div className="prix">
                                        <label for="a" class="close-btn fas fa-times" title="close"></label>
                                        <div class="msg">
                                            Mettre le code ici
                                            <div className="datee">
                                                <input id="baraka02" type="password" required></input>
                                            </div>
                                            <div class="btn">
                                                <div class="inner"></div>
                                                <button onClick={() => testerCode(8, "baraka02")} type="submit">Verifier</button>
                                            </div>
                                            <div className="p">
                                                <p  >{result}</p>
                                            </div>
                                        </div>

                                    </div></>}

                            </div>
                        </div>
                    </div>
                    <div class="card middle">
                        <div class="front">
                            <img src={Trans} alt="" />
                        </div>
                        <div class="back">
                            <div class="back-content middle">
                                <span> Réaménagement des
                                    locaux bureautique TRANSCOM - Poste de
                                    transformation
                                    1x630 KVA
                                    Sec + Groupe electrogene
                                    400KVA  <br />{codes.Transcom01 ? <><br /> 620.522,400dt</> : <></>}</span>
                                <br />
                                <br />
                                <br />
                                <br />
                                {codes.Transcom01 ? <></> : <><input type="checkbox" id="b" />
                                    <label for="b" class="show-btn">Voir prix</label>
                                    <div className="prix">
                                        <label for="b" class="close-btn fas fa-times" title="close"></label>
                                        <div class="msg">
                                            Mettre le code ici
                                            <div className="datee">
                                                <input id="Transcom01" type="password" required></input>
                                            </div>
                                            <div class="btn">
                                                <div class="inner"></div>
                                                <button onClick={() => testerCode(2, "Transcom01")} type="submit">Verifier</button>
                                            </div>
                                            <div className="p">
                                                <p  >{result}</p>
                                            </div>
                                        </div>

                                    </div></>}


                            </div>
                        </div>
                    </div>
                    <div class="card middle">
                        <div class="front">
                            <img src={sarto} alt="" />
                        </div>
                        <div class="back">
                            <div class="back-content middle">
                                <span>Augmentation de puissance
                                    du postede transformation
                                    prive1x1250 kva
                                    STEDIM sis à DJBEL L OUSET <br />{codes.Stedim01 ? <><br /> 180.000,000dt</> : <></>}</span>
                                <br />
                                <br />
                                <br />
                                <br />
                                {codes.Stedim01 ? <></> : <><input type="checkbox" id="c" />
                                    <label for="c" class="show-btn">Voir prix</label>
                                    <div className="prix">
                                        <label for="c" class="close-btn fas fa-times" title="close"></label>
                                        <div class="msg">
                                            Mettre le code ici
                                            <div className="datee">
                                                <input id="Stedim01" type="password" required></input>
                                            </div>
                                            <div class="btn">
                                                <div class="inner"></div>
                                                <button onClick={() => testerCode(6, "Stedim01")} type="submit">Verifier</button>
                                            </div>
                                            <div className="p">
                                                <p  >{result}</p>
                                            </div>
                                        </div>

                                    </div></>}


                            </div>
                        </div>
                    </div>

                    <div class="card middle">
                        <div class="front">
                            <img src={misfat} alt="" />
                        </div>
                        <div class="back">
                            <div class="back-content middle">
                                <span>
                                    Rénovation du poste de
                                    transformation prive1x1000 kva
                                    de l'usine Misfat 1 sis a la zone industrielle ejdaida _ la manouba
                                    <br />{codes.Misfat01 ? <><br />215.149,108dt</> : <></>}</span>
                                <br />
                                <br />
                                <br />
                                <br />
                                {codes.Misfat01 ? <></> : <><input type="checkbox" id="e" />
                                    <label for="e" class="show-btn">Voir prix</label>
                                    <div className="prix">
                                        <label for="e" class="close-btn fas fa-times" title="close"></label>
                                        <div class="msg">
                                            Mettre le code ici
                                            <div className="datee">
                                                <input id="Misfat01" type="password" required></input>
                                            </div>
                                            <div class="btn">
                                                <div class="inner"></div>
                                                <button onClick={() => testerCode(12, "Misfat01")} type="submit">Verifier</button>
                                            </div>
                                            <div className="p">
                                                <p  >{result}</p>
                                            </div>
                                        </div>

                                    </div></>}

                            </div>
                        </div>
                    </div>
                    <div class="card middle">
                        <div class="front">
                            <img src={safia} alt="" />
                        </div>
                        <div class="back">
                            <div class="back-content middle">
                                <span> Poste de transformation
                                    2x1250 KVA unité
                                    d'injection pour usine
                                    safia 2 pour le
                                    compte de SOSTEM <br /> <br />{codes.Safia ? <><br />365.000,000dt</> : <></>}</span>
                                <br />
                                <br />
                                <br />
                                <br />
                                {codes.Safia ? <></> : <><input type="checkbox" id="f" />
                                    <label for="f" class="show-btn">Voir prix</label>
                                    <div className="prix">
                                        <label for="f" class="close-btn fas fa-times" title="close"></label>
                                        <div class="msg">
                                            Mettre le code ici
                                            <div className="datee">
                                                <input id="Safia" type="password" required></input>
                                            </div>
                                            <div class="btn">
                                                <div class="inner"></div>
                                                <button onClick={() => testerCode(20, "Safia")} type="submit">Verifier</button>
                                            </div>
                                            <div className="p">
                                                <p  >{result}</p>
                                            </div>
                                        </div>

                                    </div></>}


                            </div>
                        </div>
                    </div>
                    <div class="card middle">
                        <div class="front">
                            <img src={ug} alt="" />
                        </div>
                        <div class="back">
                            <div class="back-content middle">
                                <span>Extension de l'usine de traitement, liquefaction
                                    et stockage du dioxyde de
                                    carbone naturel d'UTIQUE
                                    GAZ  POSTE DE
                                    TRANSFORLATION ET
                                    DE LIVRAISON
                                    1x1600kva+1X800KVA <br />{codes.UG01 ? <><br /> 390.000,000dt</> : <></>}</span>
                                <br />
                                <br />


                                {codes.UG01 ? <></> : <><input type="checkbox" id="g" />
                                    <label for="g" class="show-btn">Voir prix</label>
                                    <div className="prix">
                                        <label for="g" class="close-btn fas fa-times" title="close"></label>
                                        <div class="msg">
                                            Mettre le code ici
                                            <div className="datee">
                                                <input id="UG01" type="password" required></input>
                                            </div>
                                            <div class="btn">
                                                <div class="inner"></div>
                                                <button onClick={() => testerCode(7, "UG01")} type="submit">Verifier</button>
                                            </div>
                                            <div className="p">
                                                <p  >{result}</p>
                                            </div>
                                        </div>

                                    </div></>}


                            </div>
                        </div>
                    </div>
                    <div class="card middle">
                        <div class="front">
                            <img src={next} alt="" />
                        </div>
                        <div class="back">
                            <div class="back-content middle">
                                <span>Poste de transformation 3x1250 kva NEXTPROTEIN
                                    sis à Menzel Bouzelfa  <br />{codes.Protein ? <><br /> 78.880,000dt</> : <></>}</span>
                                <br />
                                <br />
                                <br />
                                <br /><br /><br />
                                {codes.Protein ? <></> : <><input type="checkbox" id="k" />
                                    <label for="k" class="show-btn">Voir prix</label>
                                    <div className="prix">
                                        <label for="k" class="close-btn fas fa-times" title="close"></label>
                                        <div class="msg">
                                            Mettre le code ici
                                            <div className="datee">
                                                <input id="Protein" type="password" required></input>
                                            </div>
                                            <div class="btn">
                                                <div class="inner"></div>
                                                <button onClick={() => testerCode(21, "Protein")} type="submit">Verifier</button>
                                            </div>
                                            <div className="p">
                                                <p  >{result}</p>
                                            </div>
                                        </div>

                                    </div></>}


                            </div>
                        </div>
                    </div>
                    <div class="card middle">
                        <div class="front">
                            <img src={ennasim} alt="" />
                        </div>
                        <div class="back">
                            <div class="back-content middle">
                                <span>Construction du polyclinique Ennasim
                                    DJERBA - Poste de transformation 1x1000
                                    KVA sous cabine + TGBT SECOURUE  <br />{codes.Ennasim ? <><br /> 78.880,000dt</> : <></>}</span>

                                <br />
                                <br />
                                <br /><br /><br />
                                {codes.Ennasim ? <></> : <><input type="checkbox" id="m" />
                                    <label for="m" class="show-btn">Voir prix</label>
                                    <div className="prix">
                                        <label for="m" class="close-btn fas fa-times" title="close"></label>
                                        <div class="msg">
                                            Mettre le code ici
                                            <div className="datee">
                                                <input id="Ennasim" type="password" required></input>
                                            </div>
                                            <div class="btn">
                                                <div class="inner"></div>
                                                <button onClick={() => testerCode(22, "Ennasim")} type="submit">Verifier</button>
                                            </div>
                                            <div className="p">
                                                <p  >{result}</p>
                                            </div>
                                        </div>

                                    </div></>}


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Ge
