import React from 'react';
import '../../../App.css';
import Footer from '../Footer';
import Sidebar from '../Sidebar';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Center from '../Center';
import Faible from '../Faible';
import Fort from '../Fort';
import Systeme from '../Systeme';
import Trans from '../Trans';
import Electro from '../Electro';
import Navbar from '../Navbar';


function Services() {
    
    return (
        <>
            <Router>
                <Switch>
                    <Route path='/service/' exact component={Center} />
                    <Route path='/faible' exact component={Faible} />
                    <Route path='/fort' exact component={Fort} />
                    <Route path='/securité' exact component={Systeme} />
                    <Route path='/transformation' exact component={Trans} />
                    <Route path='/electrogéne' exact component={Electro} />





                </Switch>
                
            </Router>



        </>
    );
}

export default Services;
