import React from 'react'
import './Projet.css'
import un from '../images/un.png';
import deux from '../images/deux.jpg';
import quatr from '../images/quatr.jpg';
import cinq from '../images/cinq.jpg';
import six from '../images/six.jpg';
import huit from '../images/huit.jpg';
import neuf from '../images/neuf.jpg';
import onze from '../images/onze.jpg';
import quatorze from '../images/quatorze.jpg';
import Quinze from '../images/Quinze.jpg';
import seiz from '../images/seiz.jpg';
import dixsept from '../images/dixsept.jpg';
import dixneuf from '../images/dixneuf.png';
import dixhuit from '../images/dixhuit.png';
import vingt from '../images/vingt.png';
import vingtun from '../images/vingtun.jpg';




function Projet() {
    return (
        <>
        <div className="pho">
            <div className="photoss">
                <div class="grid-container">
                    <div>
                        <img class='grid-item grid-item-1' src={neuf} alt='' />
                        <p>""</p>
                    </div>
                    <div>
                        <img class='grid-item grid-item-2' src={quatorze} alt='' />
                        <p>""</p>
                    </div>
                   
                    <div>
                        <img class='grid-item grid-item-4' src={quatr} alt='' />
                        <p>""</p>
                    </div>
                    <div>
                        <img class='grid-item grid-item-5' src={vingtun} alt='' />
                        <p>""</p>
                    </div>
                    <div>
                        <img class='grid-item grid-item-6' src={cinq} alt='' />
                        <p>""</p>
                    </div>
                    
                    <div>
                        <img class='grid-item grid-item-8' src={dixneuf} alt='' />
                        <p>""</p>
                    </div>
                    <div>
                        <img class='grid-item grid-item-9' src={dixhuit} alt='' />
                        <p>""</p>
                    </div>
                    
                    <div>
                        <img class='grid-item grid-item-11' src={Quinze} alt='' />
                        <p>""</p>
                    </div>
                    <div>
                        <img class='grid-item grid-item-17' src={six} alt='' />
                        <p>""</p>
                    </div>
                    <div>
                        <img class='grid-item grid-item-18' src={dixhuit} alt='' />
                        <p>""</p>
                    </div>
                                                                                                                                                                           
                    <div>
                        <img class='grid-item grid-item-14' src={deux} alt='' />
                        <p>""</p>
                    </div>
                    <div>
                        <img class='grid-item grid-item-15' src={huit} alt='' />
                        <p>""</p>
                    </div>
                    <div>
                        <img class='grid-item grid-item-16' src={un} alt='' />
                        <p>""</p>
                    </div>
                   
                    <div>
                        <img class='grid-item grid-item-19' src={dixsept} alt='' />
                        <p>""</p>
                    </div>
                    <div>
                        <img class='grid-item grid-item-20' src={seiz} alt='' />
                        <p>""</p>
                    </div>
                    <div>
                        <img class='grid-item grid-item-21' src={onze} alt='' />
                        <p>""</p>
                    </div>
                    <div>
                        <img class='grid-item grid-item-22' src={vingt} alt='' />
                        <p>""</p>
                    </div>
                </div>

            </div>
        </div>
        
        </>
    )
}

export default Projet
