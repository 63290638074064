import React, { useState, useEffect } from 'react'
import { Button } from './Button';
import Logo from "../images/logo.png";

import './Navbar.css';
import french from '../images/french.png';
import english from '../images/english.png';
import { HashLink } from 'react-router-hash-link';



function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);


  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className="navbar">
        <div className='navbar-container'>
          <HashLink smooth to='/home#hero' className='navbar-logo' onClick={closeMobileMenu}>
            <img src={Logo} alt="Logo" />
          </HashLink>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <HashLink smooth to='/home#hero' className='nav-links' onClick={closeMobileMenu}>
                Home
              </HashLink>
            </li>
            <li className='nav-item'>
              <HashLink smooth
                to='/about#tete'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                About
              </HashLink>
            </li>

            <li className='nav-item'>
              <HashLink smooth
                to='/service/#data'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Services
              </HashLink>
            </li>

            <li className='nav-item'>
              <p
                className='nav-links'
              >
                References<i class="fas fa-caret-down"></i>
                <ul>
                  <li><HashLink smooth to='/office#Office-building'  className='nav-links'  onClick={closeMobileMenu}>Office building </HashLink></li>
                  <li><HashLink smooth to='/dataa#data-center'  className='nav-links'  onClick={closeMobileMenu}>Data center</HashLink></li>
                  <li><HashLink smooth to='/industry#industrie'   className='nav-links' onClick={closeMobileMenu}>Industry </HashLink></li>
                  <li><HashLink smooth to='/gee#transformation' className='nav-links'  onClick={closeMobileMenu}>Transformation Stations & GE</HashLink> </li>
               </ul>

                </p>
            </li>
           



            <li>
              <HashLink smooth
                to='/contactt'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                Contact
              </HashLink>
            </li>
            <li className='nav-item'>
              <HashLink smooth to="/#hero" className="img" onClick={closeMobileMenu}><img src={french} alt="" /></HashLink>
              <HashLink smooth to="/home#hero" className="img" onClick={closeMobileMenu}> <img src={english} alt="" /></HashLink>
            </li>
            
          </ul>


          <HashLink smooth to='/contactt'>
            {button && <Button buttonStyle='btn--outline'>Contact</Button>}
          </HashLink>

        </div>

      </nav>
    </>
  )
}

export default Navbar;
