import React from 'react';
import './Electro.css';
import electro from "../images/elect.jpg";
import elect from "../images/elect1.jpg";
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';



function Electro() {
    return (
        <>
                                   <Navbar/>


        <div className="electro">
            <Sidebar />

            <div className="contenu">
            <div className="texts">
                <p>Un groupe électrogène est l’association d’une machine produisant de l’énergie mécanique et d’un alternateur. Une centrale de production électrique est en fait un « groupe électrogène couplé à une “chaudière” ». <br /> <br /> Pour qu’un groupe électrogène ou alternateurs fonctionnent, il faut une régulation de vitesse sur le moteur et une régulation de tension sur l’alternateur. De plus, ils doivent être munis d’un ensemble de capteurs et de sécurités. Enfin, ils sont équipés d’un système de gestion du démarrage et de l’arrêt qui peut être manuel ou automatique</p>
            </div>
            <div className="partone">
                <div className="imag">
                    <img src={elect} alt="" />

                </div>
                <div className="tex">
                    <p>Comme nous l’avons vu précédemment, il existe différentes catégories de personnes susceptibles d’utiliser des groupes électrogènes. Selon qu’il s’agit de besoins particuliers ou professionnels. Sur le plan professionnel, les utilisations sont également diverses selon les besoins et les circonstances. Par exemple, un hôpital se doit d’avoir un groupe électrogène de secours en remplacement de la source principale. Il doit être d’une puissance importante au regard des consommations énergétiques constatées dans ce type de milieu.</p>
                </div>

            </div>
            <div className="partone">

                    <div className="tex">
                        <p>Le groupe électrogène sert à la production énergétique, alimentant un réseau de distribution. Ce type d’installation trouve son application sur des sites où le réseau de distribution public est inexistant, et techniquement ou économiquement impossible à mettre en place, tel qu’en montagne, ou encore sur une île. La durée de fonctionnement annuelle de ces groupes électrogènes est souvent très élevée.</p>
                    </div>
                    <div className="imag">
                        <img src={electro} alt="" />

                    </div>
                </div>
                </div>

        </div>
        <Footer />

     </>
    )
}

export default Electro
