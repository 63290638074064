import React from 'react';
import Footer from '../Footer'; 
import '../../App.css';
import Body from '../Body';
import Navbar from '../Navbar';
import emp from '../../images/emp.png';



function Propos() {
    return (
        <>
        <Navbar />
        <section id="tete" className="pros">
            <div className="slantedDivA">

                <img src={emp} alt="" />
                <p> A propos de SMEEE...</p>
            </div>
            

        </section>
            <Body/>
            <Footer />
        </>
    );
}

export default Propos;
