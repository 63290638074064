import React from 'react'
import  './Sidebar.css';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Center from './Center';
import Faible from './Faible';
import Fort from './Fort';
import Systeme from './Systeme';
import Trans from './Trans';
import Electro from './Electro';


function Sidebar() {
    return (

     <div className="wrapper">
      <nav id="sidebar">

        <ul className="list-items">
          <li><Link to="/services/"><i class="fas fa-desktop"></i>Data Centers</Link></li>
          <li><Link to="/faible"><i class="fas fa-charging-station"></i>Courants Faibles</Link></li>
          <li><Link to="/fort"><i class="fas fa-bolt"></i>Courants Forts</Link></li>
          <li><Link to="/transformation"><i class="fas fa-redo"></i>Postes Transformations</Link></li>
          <li><Link to="/electrogéne"><i class="fas fa-trailer"></i>Groupes Electrogénes</Link></li>
          <li><Link to="/securité"><i class="fas fa-shield-alt"></i>Securité Incendie</Link></li>

         
          
        </ul>
      </nav>
      
      
      
     
    </div>
    
    
 
       
    );
}

export default Sidebar;
