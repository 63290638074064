import React from 'react'
import './Center.css';
import data from '../images/data.jpg';
import datta from '../images/data1.jpg';
import Sidebarr from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer'

function Center() {
    return (
        <>
                <Navbar/>

            <div id ="data"className="cent">
            <Sidebarr />
              <div className="contenu">
                <div className="partone">
                    <div className="imag">
                        <img src={data} alt="" />

                    </div>
                    <div className="tex">
                        <p>The data center, or data center in French, refers to a physical place where different computer equipment is grouped together, such as computers, servers, etc. Its main function is to store information useful for the normal functioning of the company. A data center, depending on its size, storage capacity and other characteristics, can indeed store billions of customers and personal data. </p>
                    </div>
                </div>

                <div className="text">
                    <h2>Data center: architecture and design </h2>

                    <p> In theory, any space large enough can be used as a data center. However, the design and implementation of data centers requires some care. In addition to the basic questions of cost and taxation, there are many criteria for selecting a site, such as location, weather stability, accessibility of roads and airports, availability of energy, telecommunications or the environment. Politics.

Once the site is secure, you can focus on the electrical and mechanical infrastructure, as well as the composition and availability of IT equipment, when designing the data center architecture. All these standards depend on the third of the target data center</p>
                </div>
                <div className="partone">

                    <div className="tex">
                        <p>Cloud computing infrastructure is mainly made up of servers located in data centers. They of course consume energy, dissipate heat and require energy to cool. Data center consumption is an issue that is receiving increasing attention and is closely linked to its proliferation. Numerous studies have attempted to determine the energy impact of these data centers. For companies that have not increased their energy consumption but have doubled in a few years, it is vital to establish the consumption of key technologies. This is the first step in reducing the negative impact of the data center on the environment.</p>
                    </div>
                    <div className="imag">
                        <img src={datta} alt="" />

                    </div>
                </div>

            </div>
            </div>
            <Footer/>

        </>
    )
}

export default Center
