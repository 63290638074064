import React ,{useState} from 'react';
import './Body.css';
import el from '../images/elecc.jpg';
import baraka from '../images/baraka.png';
import bh from '../images/bh.png';
import biat from '../images/biat.png';
import { Link } from 'react-router-dom';
import rec from '../images/reclamtion.png';
import axios from 'axios'

function Body() {





    const [email,setemail] = useState("")
    const [message,setmessage] = useState("")

    const [result,setResult] = useState("")

    const handleFormSubmit = (e) => {
        e.preventDefault()
        console.log({
            email,
            message
        })
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: {
                
                email,
                message
            }
          })
            .then(result => {
              console.log(result)
              setResult(result.data)
              setemail("")
              setmessage("")
            })
            .catch(error => {console.log("oups")});
    }
    const API_PATH = "/reclamation.php";








    return (
        <div className="body">
            <div className="apropos-text">
                <h3>Qui sommes-nous ? </h3>
                <p> SMEEE est une jeune entreprise qui a
                    obtenu un succès dans le domaine de l’installation
                    électrique en Tunisie depuis sa création en 2014
                    par  <strong>Monsieur Haddad Zouhair</strong>. Elle conçoit l’étude
                    et l’exécution des travaux électriques dans les
                    secteurs du bâtiment et de l’industrie répondant à
                    la norme internationale NFC 15-1 Ainsi, elle assure
                    la maintenance industrielle grâce à son staff
                    qualifie compose de techniciens et d’ingénieurs
                    spécialises dans de divers domaines tels que
                    l’informatique industriel, l’automatisme et la
                    détection d’incendie. <br/>
                    <br/> <br/> <strong>Aussi SMEEE est l'une des 257 entreprises agrées par les 
                         autorités compétentes par le STEG <a href="https://www.steg.com.tn/dwl/Entreprises_Agrees.pdf"> (Voir ligne 134 dans le tableau ) </a>
                     </strong>
                    
                    </p>
                    

            </div>

            <div className="qualite">
                <div className="quality">
                    <div className="icon">
                        <i class="fas fa-cogs"></i>
                    </div>
                    <h3>Travail de qualité</h3>
                    <p>Notre expérience et notre savoir-faire nous
                        permettent de vous accompagner dans
                        le développement de projet depuis le début
                        du projet jusqu'à la phase d'exécution
                        et de réalisation.
                    </p>
                </div>
                <div className="quality">
                    <div className="icon">
                        <i class="fas fa-user-friends"></i>
                    </div>
                    <h3>Satisfaction de nos clients</h3>
                    <p>Avec nos objectifs fixées depuis notre lancement ,
                        on essaye toujours de vous montrer
                        que nous mettons votre satisfaction
                        au coeur de nos priorités. Et grace a votre recommandation
                        et fidelisation , on augmente ensemble .
                    </p>
                </div>
                <div className="quality">
                    <div className="icon">
                    <i class="fas fa-lightbulb"></i>
                    </div>
                    <h3>Intégrité & Innovation </h3>
                    <p>L'intégrité s'agit de communiquer
                         honnêtement pour créer une atmosphère de confiance et de respect.
                         Aussi nous plaçons l'innovation au cœur de nos actions
                         , car sur le marché, notre réactivité
                          est un atout nécessaire et important
                    </p>
                </div>
            </div>
            <div className="ph" style={{ backgroundImage: `url(${el})` }}></div>
            <div className="horraire">
                <i class="far fa-clock"> Horaires d’ouverture </i>
                <div className="time">
                    <div className="tim normal">
                        <h4> Tous les jours </h4>
                        <div className="days">
                            <div className="day">
                                <h6>Lundi</h6>
                                <p>08:30-17:00</p>
                            </div>
                            <div className="day">
                                <h6>Mardi</h6>
                                <p>08:30-17:00</p>
                            </div>
                            <div className="day">
                                <h6>Mercredi</h6>
                                <p>08:30-17:00</p>
                            </div>
                            <div className="day">
                                <h6>Jeudi</h6>
                                <p>08:30-17:00</p>
                            </div>
                            <div className="day">
                                <h6>Vendredi</h6>
                                <p>08:30-17:00</p>
                            </div>
                            <div className="day">
                                <h6>Samedi</h6>
                                <p className="ferm">Fermé</p>
                            </div>
                            <div className="day">
                                <h6>Dimanche</h6>
                                <p className="ferm">Fermé</p>
                            </div>
                        </div>
                    </div>
                    <div className="tim ete">
                        <h4>Eté</h4>
                        <div className="days">
                            <div className="day">
                                <h6> Lundi</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6> Mardi</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6> Mercredi</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6> Jeudi</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6> Vendredi</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6> Samedi</h6>
                                <p className="ferm">Fermé</p>
                            </div>
                            <div className="day">
                                <h6> Dimanche</h6>
                                <p className="ferm">Fermé</p>
                            </div>
                        </div>
                    </div>
                    <div className="tim ramadan">
                        <h4>Moins du Ramadan</h4>
                        <div className="days">
                            <div className="day">
                                <h6>Lundi</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Mardi</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Mercredi</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Jeudi</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Vendredi</h6>
                                <p>08:30-16:00</p>
                            </div>
                            <div className="day">
                                <h6>Samedi</h6>
                                <p className="ferm">Fermé</p>
                            </div>
                            <div className="day">
                                <h6>Dimanche</h6>
                                <p className="ferm">Fermé</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div className="work">
                <div className="picture">
                    <img src={baraka} alt="" />

                </div>
                <div className="picture">
                    <img src={bh} alt="" />

                </div>
                <div className="picture">
                    <img src={biat} alt="" />

                </div>
                <div className="picture">
                    <Link to='/references'>
                        <p>Voir plus </p>
                    </Link>
                </div>
            </div>
            <div className="reclamtion">
                <div className="recltext">
                    <h4>Pour tous types  de réclamations...</h4>


                </div>
                <img src={rec} alt="" />
                <div className="reclForm">
                    <form onSubmit={handleFormSubmit}>
                        <div className="inputBox w100">
                            <input value={email} onChange={(e)=>setemail(e.target.value)} type="email" name="email" required />
                            <span>E-mail</span>
                        </div>
                        <div className="inputBox w100">
                            <textarea value={message} onChange={(e)=>setmessage(e.target.value)} name="message" required></textarea>
                            <span>Taper votre message ici</span>
                        </div>
                        <div className="inputBox w50">
                            <input type="submit" value="Envoyer" />
                        </div>
                        <div className="inputBox w50">
                            <p>{result}</p>
                        </div>
                    </form>
                </div>

            </div>
            <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.15696032061!2d10.154981250212941!3d36.83871677313305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd332371a864df%3A0x16a4c1cc93498805!2sSMEEE%20(%20St%C3%A9%20Malek%20Etude%20Ex%C3%A9cution%20Electricit%C3%A9%20)!5e0!3m2!1sfr!2stn!4v1631553110726!5m2!1sfr!2stn" ></iframe>

            </div>

        </div>
    );
}

export default Body;
