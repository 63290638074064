import React from 'react';
import './Systeme.css';
import inc from '../images/inc.jpg';
import ince from '../images/incendie.jpg'
import Sidebarr from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';


function Systeme() {
    return (
        <>
        <Navbar/>
        <div className="systemee">
            <Sidebarr />
            <div className="contenu">

                <div className="texts">
                    <p>A fire safety system is a piece of equipment that makes it possible to secure the establishment as soon as a sign of fire appears.

                        In an establishment open to the public or in high-rise buildings, the fire safety system is one of the emergency means that can be imposed either by the safety regulations or by the safety commission</p>
                </div>
                <div className="partone">
                    <div className="imag">
                        <img src={inc} alt="" />

                    </div>
                    <div className="tex">
                        <p>With the primary goal of ensuring the safety of people, facilitating the intervention of firefighters and limiting the spread of fire, the device triggers several safety actions.

                            These actions are classified into four families of functions that we note:  </p>
                        <ul>
                            <li>The purpose of the compartmentalisation function is to limit the spread of fire by closing the fire doors and the fire dampers in the ducts. </li>

                            <li>The purpose of the smoke extraction function is to evacuate toxic, hot, burning, opaque fumes. </li>

                            <li> The purpose of the technical stop function is to limit the spread of fire by stopping certain installations such as the air conditioning unit, and / or to prevent the elevators from stopping at the disaster level. </li>


                            <li> The purpose of the evacuation function is to ensure the safety of people by unlocking the emergency exits and triggering the general evacuation alarm. </li>
                        </ul>
                    </div>

                </div>
                <div className="partone">

                    <div className="tex">
                        <p>The function of the fire detection system is to detect signs of fire, either automatically or manually.

                            It's made of : </p>
                        <ul>
                            <li>Automatic fire detectors: round boxes, located on the ceiling, sensitive to the effects of fire (smoke, heat, radiation). </li>
                            <li>Manual triggers: red square boxes, generally located in corridors, near exits, actuated by a person witnessing a fire. </li>
                            <li>Signaling panel: generally located at the level of the security PC. It allows the user to locate the incident in the building by addressing the smoke detectors to which a text indicating its location is linked. (For example: "DI R + 1 SOUTH Wing local household" </li>
                            <li>Action indicators: optional devices of variable shape, equipped with a red diode. They are located outside and above the doors of the premises. The diode is activated in the event of detection in the corresponding room. </li>

                        </ul>

                    </div>
                    <div className="imag">
                        <img src={ince} alt="" />

                    </div>
                </div>

            </div>
        </div>
        <Footer />

        </>
    )
}

export default Systeme
