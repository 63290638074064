import React from 'react';
import './Systeme.css';
import inc from '../images/inc.jpg';
import ince from '../images/incendie.jpg';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';



function Systeme() {
    return (
        <>
                                           <Navbar/>

        <div className="systemee">
            <Sidebar />
            <div className="contenu">
                <div className="texts">
                    <p>Un système de sécurité incendie est un équipement qui permet de mettre en sécurité l'établissement dès l'apparition d'un signe de feu.

                        Dans un établissement recevant du Public ou dans les immeubles de grande hauteur, le système de sécurité incendie est un des moyens de secours qui peut être imposé soit par le règlement de sécurité, soit par la commission de sécurité.</p>
                </div>
                <div className="partone">
                    <div className="imag">
                        <img src={inc} alt="" />

                    </div>
                    <div className="tex">
                        <p>Ayant pour but premier d'assurer la sécurité des personnes, faciliter l’intervention des pompiers et limiter la propagation du feu, l'appareil provoque plusieurs actions de sécurité.

                            Ces actions sont classées en quatre familles de fonction que l'on note :  </p>
                        <ul>
                            <li> La fonction compartimentage a pour but de limiter la propagation du feu en fermant les portes coupe-feu et les clapets coupe-feu des conduits.</li>

                            <li>La fonction désenfumage a pour but d'évacuer les fumées toxiques, chaudes, brûlantes, opaques.</li>

                            <li> La fonction arrêt technique a pour but de limiter la propagation du feu en arrêtant certaines installations comme la centrale de climatisation, et/ou d'empêcher les ascenseurs de s'arrêter au niveau sinistré.</li>


                            <li> La fonction évacuation a pour but d'assurer la sécurité des personnes en déverrouillant les issues de secours, et en déclenchant l'alarme générale d'évacuation.</li>
                        </ul>
                    </div>

                </div>
                <div className="partone">

                    <div className="tex">
                        <p>Le système de détection incendie a pour fonction de détecter les signes du feu, de façon automatique ou manuelle.

                            Il se compose de :</p>
                        <ul>
                            <li>Détecteurs automatiques d’incendie : boîtiers de forme ronde, situés au plafond, sensibles aux effets induits par le feu (fumée, chaleur, rayonnement).</li>
                            <li>Déclencheurs manuels : boîtiers de forme carrée de couleur rouge, situés en général dans les circulations, près des sorties, actionnés par une personne témoin d'un feu.</li>
                            <li>Tableau de signalisation : situé, en général, au niveau du PC sécurité. Il permet à l'utilisateur de situer le sinistre dans le bâtiment grâce à un adressage des détecteurs de fumée auquel est lié un texte indiquant son emplacement. (Par exemple:" DI R+1 Aile SUD local ménage"</li>
                            <li>Indicateurs d'action : appareils optionnels et de forme variable, dotés d'une diode rouge. Ils sont situés à l'extérieur et au-dessus des portes des locaux. La diode est activée en cas de détection dans le local correspondant.</li>

                        </ul>

                    </div>
                    <div className="imag">
                        <img src={ince} alt="" />

                    </div>
                </div>

            </div>
        </div>
        <Footer />

        </>

    );
}

export default Systeme;
