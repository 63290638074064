import React from 'react';
import '../../../App.css';
import Partenaires from '../Partenaires';
import Projet from '../Projet';
import Footer from '../Footer';
import Navbar from '../Navbar';
import Vid from '../../videos/v1.mp4'; 
import Ref from '../Ref';



function Acceuil() {
  return (
    <>
    <Navbar/>
    <div id="hero" className='hero-container'>
      <video src={Vid} autoPlay loop muted />
      <h1>SOCIETE MALEK  ETUDE ET EXECUTION <br/>  DES TRAVAUX ELECTRIQUES</h1>
      <p>Study and Execution of High & Low Current Electricity Works </p>
      </div>
      <Ref />
      <Partenaires />
      <Projet />
      <Footer/>
      

     
    </>
  );
}

export default Acceuil ; 