import React, { useState } from 'react';
import '../App.css';
import Footer from './Footer';
import Navbar from './Navbar';

import Mall from '../images/mall.png'

import onh from '../images/onh.png'

import volvo from '../images/volvo.png'
import misfat from '../images/misfat.png'
import sarto from '../images/sarto.png'

import next from '../images/next.png'
import ug from '../images/utique.png';

import tom from '../images/TOM.png';

import './Industrie.css';


import axios from 'axios';



function Industrie() {

    const [codes, setCodes] = useState({
        Mall: false,
        Transcom: false,
        LNCM: false,
        ONH: false,
        BIAT: false,
        Stedim: false,
        UG: false,
        baraka: false,
        Hotel: false,
        Volvo: false,
        Business: false,
        Misfat: false,
        TSB: false,
        Onas: false,
        UIB: false,
        BT: false,
        BH: false,
        Meninix: false,
        Enda: false,
        Safia: false,
        Protein: false,
        Ennasim: false,
        baraka01: false,
        Misfat01: false,
        LNCM01: false,
        Stedim01: false,
        Transcom01: false,
        UG01: false,
        baraka02: false,
        Marsa: false,

    })

    const testerCode = (id, name) => {
        console.log(document.getElementById(name).value)
        const API_PATH = "/testerCode.php"
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: {
                idSociete: id,
                password: document.getElementById(name).value
            }
        })
            .then(result => {
                console.log(result)
                if (result.data == "update done") {
                    setCodes({ ...codes, [name]: true })
                    console.log(codes)
                    console.log("changing")
                }
            })
            .catch(error => { console.log("oups") });
    }

    return (
        <>
            <Navbar />
           
           
           

            <section id="industrie" className="indus">
                <h1>Industrie</h1>
                <div className="indust">
                    <div className="picture">
                        <img src={misfat} alt="" />

                    </div>
                    <div className="picture">
                        <img src={Mall} alt="" />

                    </div>
                    <div className="picture">
                        <img src={sarto} alt="" />

                    </div>
                    <div className="picture">
                        <img src={volvo} alt="" />

                    </div>
                    <div className="picture">
                        <img src={onh} alt="" />

                    </div>
                    <div className="picture">
                        <img src={ug} alt="" />

                    </div>
                    <div className="picture">
                        <img src={next} alt="" />

                    </div>
                    <div className="picture">
                        <img src={tom} alt="" />

                    </div>
                </div>


            </section>

           
            <Footer />

        </>
    );
}

export default Industrie;
