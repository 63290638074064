import React from 'react';
import '../../../App.css';
import Footer from '../Footer';
import Contact from '../Contact';
import Navbar from '../Navbar';

function Contactt() {
    return (
        <>
        <Navbar/>
        <Contact />
        <Footer />
        </>

    )
}

export default Contactt; 
