import React,{useEffect, useState} from 'react';
import font from '../../images/font.jpg'
import './Code.css';
import { useHistory } from 'react-router';

import axios from 'axios';
function Code(props) {

      const loggedIn = props.loggedIn
      const history = useHistory()

      useEffect(() => {
            console.log(props)
            if (!loggedIn)
                  history.push("/login")
      }, [])

      const [codes,setCodes]=useState({
            Mall:"",
            Transcom:"",
            LNCM:"",
            ONH:"",
            BIAT:"",
            Stedim:"",
            UG:"",
            baraka:"",
            Hotel:"",
            Volvo:"",
            Business:"",
            Misfat:"",
            TSB:"",
            Onas:"",
            UIB:"",
            BT:"",
            BH:"",
            Meninix:"",
            Enda:"",
            Safia:"",
            Protein:"",
            Ennasim:"",
            Marsa :""
      })
      const changerCode = (id,name) => {
            console.log(codes[name])

            const API_PATH = "/changerCode.php"
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: {
                idSociete:id,
                newCode:codes[name]
            }
          })
            .then(result => {
                  setCodes({...codes,[name]:""})
              console.log(result)
              
            })
            .catch(error => {console.log("oups")});

      }
    return (
        <div className="codee" style={{ backgroundImage: `url(${font})` }}>
            <h1> Vous pouvez changer les codes ici </h1>
            <div className="code">
            <div className="mot">
                  <h4>Mall of Sousse</h4>
                  <input value={codes.Mall} onChange={(e)=>setCodes({...codes,Mall:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(1,"Mall")} type="submit" id='submit' value='OK' ></input>
            </div>

            <div className="mot">
                  <h4>Transcom</h4>
                  <input value={codes.Transcom} onChange={(e)=>setCodes({...codes,Transcom:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(2,"Transcom")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>LNCM</h4>
                  <input value={codes.LNCM} onChange={(e)=>setCodes({...codes,LNCM:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(3,"LNCM")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>ONH</h4>
                  <input value={codes.ONH} onChange={(e)=>setCodes({...codes,ONH:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(4,"ONH")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>BIAT</h4>
                  <input value={codes.BIAT} onChange={(e)=>setCodes({...codes,BIAT:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(5,"BIAT")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>Stedim</h4>
                  <input value={codes.Stedim} onChange={(e)=>setCodes({...codes,Stedim:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(6,"Stedim")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>UG</h4>
                  <input value={codes.UG} onChange={(e)=>setCodes({...codes,UG:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(7,"UG")} type="submit" id='submit' value='OK' ></input>

            </div>
            
            <div className="mot">
                  <h4>Al baraka</h4>
                  <input value={codes.baraka} onChange={(e)=>setCodes({...codes,baraka:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(8,"baraka")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>Hotel Iliade</h4>
                  <input value={codes.Hotel} onChange={(e)=>setCodes({...codes,Hotel:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(9,"Hotel")} type="submit" id='submit' value='OK' ></input>

            </div>
           
            <div className="mot">
                  <h4>Volvo</h4>
                  <input value={codes.Volvo} onChange={(e)=>setCodes({...codes,Volvo:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(10,"Volvo")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>Business Tower </h4>
                  <input value={codes.Business} onChange={(e)=>setCodes({...codes,Business:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(11,"Business")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>Misfat</h4>
                  <input value={codes.Misfat} onChange={(e)=>setCodes({...codes,Misfat:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(12,"Misfat")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>TSB</h4>
                  <input value={codes.TSB} onChange={(e)=>setCodes({...codes,TSB:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(13,"TSB")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>Onas</h4>
                  <input value={codes.Onas} onChange={(e)=>setCodes({...codes,Onas:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(14,"Onas")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>UIB</h4>
                  <input value={codes.UIB} onChange={(e)=>setCodes({...codes,UIB:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(15,"UIB")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>BT</h4>
                  <input value={codes.BT} onChange={(e)=>setCodes({...codes,BT:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(16,"BT")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>BH</h4>
                  <input value={codes.BH} onChange={(e)=>setCodes({...codes,BH:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(17,"BH")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>Meninix</h4>
                  <input value={codes.Meninix} onChange={(e)=>setCodes({...codes,Meninix:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(18,"Meninix")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>Enda</h4>
                  <input value={codes.Enda} onChange={(e)=>setCodes({...codes,Enda:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(19,"Enda")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
                  <h4>Safia</h4>
                  <input value={codes.Safia} onChange={(e)=>setCodes({...codes,Safia:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(20,"Safia")} type="submit" id='submit' value='OK' ></input>

            </div>
            <div className="mot">
            <h4>Next Protein</h4>
            <input value={codes.Protein} onChange={(e)=>setCodes({...codes,Protein:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(21,"Protein")} type="submit" id='submit' value='OK' ></input>

      </div>
      <div className="mot">
            <h4>Polyclinique Ennasim</h4>
            <input value={codes.Ennasim} onChange={(e)=>setCodes({...codes,Ennasim:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(22,"Ennasim")} type="submit" id='submit' value='OK' ></input>

      </div>
      <div className="mot">
                  <h4>Hotel dar El Marsa</h4>
                  <input value={codes.Marsa} onChange={(e)=>setCodes({...codes,Marsa:e.target.value})} type="text" placeholder="Entrer le code"  required/>
                  <input onClick={()=>changerCode(23,"Marsa")} type="submit" id='submit' value='OK' ></input>
            </div>
      </div>

        </div>
    )
}

export default Code
